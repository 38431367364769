export default {
    handleValidatedButton(button) {
        button.removeAttribute("data-kt-indicator");
        button.classList.add("btn-success");
        setTimeout(() => {
            if (button) {
                button.classList.remove("btn-success");
            }
        }, 1000);
    },
    handleWaitingButton(button) {
        if (button) {
            button.setAttribute("data-kt-indicator", "on");
        }
    },
    handleErrorButton(button) {
        if (button) {
            button.removeAttribute("data-kt-indicator");
            button.classList.add("btn-primary");
        }
    },
};
