<template>
    <div class="row">
        <div class="col-12 mb-3">
            <div class="card mb-5 mb-xl-10">
                <div class="card-header border-0 cursor-pointer">
                    <!--begin::Card title-->
                    <div class="card-title m-0">
                        <h3 class="fw-bolder m-0">{{ $t("pages.driveManagement.categories.addCat") }}</h3>
                    </div>
                    <!--end::Card title-->
                </div>
                <Form id="addDocForm" @submit="addCategory">
                    <div class="card-body border-top p-9">
                        <!--begin::Input group-->
                        <div class="row mb-6">
                            <!--begin::Label-->
                            <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.driveManagement.categories.catTarget") }}</label>
                            <div class="col-lg-8">
                                <div class="input-group mb-5" v-for="userType in userTypes" :key="userType.id">
                                    <label class="form-check form-check-custom form-check-solid me-5">
                                        <input type="checkbox" :id="userType.id" :value="userType.id" v-model="this.category.user_types" class="form-check-input" />
                                        <span class="form-check-label"> {{ userType.label }} </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-6">
                            <!--begin::Label-->
                            <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.driveManagement.categories.catOrder") }}</label>
                            <div class="col-lg-1">
                                <div class="input-group mb-5">
                                    <input type="number" v-model="this.category.order" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="row mb-6">
                            <!--begin::Label-->
                            <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t("pages.driveManagement.categories.catLabel") }}</label>
                            <div class="col-lg-8">
                                <div class="input-group">
                                    <span class="input-group-text" id="basic-addon1">🇫🇷</span>
                                    <Field
                                        name="frFileName"
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t('pages.driveManagement.categories.frLabel')"
                                        v-model="this.category.label"
                                    />
                                </div>
                                <div class="input-group mt-5">
                                    <span class="input-group-text" id="basic-addon2">🇬🇧</span>
                                    <Field
                                        name="enFileName"
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t('pages.driveManagement.categories.enLabel')"
                                        v-model="this.category.label_en"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-end py-6 px-9">
                        <button type="reset" class="btn btn-white btn-active-light-primary me-2">{{ $t("general.cancel") }}</button>

                        <button type="submit" id="kt_account_profile_details_submit" ref="submitButton" class="btn btn-primary">
                            <span class="indicator-label"> {{ $t("general.add") }} </span>
                            <span class="indicator-progress">
                                {{ $t("general.pleaseWait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                            <span class="indicator-validate">
                                {{ $t("general.done") }}
                                <span class="fas fa-check f-5 ms-3"></span>
                            </span>
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<style></style>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field, ErrorMessage } from "vee-validate";
import apiUserProfile from "@/core/services/UserProfile";
import apiDrive from "@/core/services/Drive";
import router from "@/router";
import { useI18n } from "vue-i18n";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "administration-add-categories",
    components: {
        Form,
        Field,
    },
    data() {
        return {
            userTypes: [],
            category: {
                user_types: [],
                label: "",
                label_en: "",
                order: 1,
            },
        };
    },
    setup() {
        const { t } = useI18n();
        const submitButton = ref<HTMLElement | null>(null);
        setCurrentPageBreadcrumbs(t("pages.driveManagement.categories.addCat"), [
            {
                link: "/administration/documents/categories",
                label: t("pages.driveManagement.categories.management"),
            },
        ]);
        return { t, submitButton };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.driveManagement.categories.addCat"), [
                {
                    link: "/administration/documents/categories",
                    label: this.t("pages.driveManagement.categories.management"),
                },
            ]);
        },
    },
    created() {
        this.fetchUserTypes();
        if (this.$route.query.id) {
            apiDrive.getCategory(this.$route.query.id).then((data) => {
                let category = data.category;
                this.category.user_types = category.user_types.map((user_type) => {
                    return user_type.id;
                });
                this.category.label = category.label;
                this.category.label_en = category.label_en;
                this.category.order = category.order;
            });
        }
    },
    methods: {
        addCategory() {
            buttonHandle.handleWaitingButton(this.submitButton);
            if (this.$route.query.id) {
                apiDrive.editCategory(this.$route.query.id, this.category).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        router.push({ name: "administration-documentcategories" });
                    }, 500);
                });
            } else {
                apiDrive.addCategory(this.category).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        router.push({ name: "administration-documentcategories" });
                    }, 500);
                });
            }
        },
        fetchUserTypes() {
            apiUserProfile.getUserTypes().then((response) => {
                this.userTypes = response.data.usertypes;
            });
        },
    },
});
</script>
