import ApiService from "@/core/services/ApiService";

const GET_USERTYPES = "userprofile/get-usertypes";
const UPDATE_USERTYPES = "userprofile/update-usertypes/";

export default {
    getUserTypes() {
        ApiService.setHeader();
        return ApiService.get(GET_USERTYPES).then(response => {
            return response.data;
        });
    },
    updateUserTypes(usertypes) {
        ApiService.setHeader();
        return ApiService.post(UPDATE_USERTYPES, usertypes);
    },
};
