
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field, ErrorMessage } from "vee-validate";
import apiUserProfile from "@/core/services/UserProfile";
import apiDrive from "@/core/services/Drive";
import router from "@/router";
import { useI18n } from "vue-i18n";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "administration-add-categories",
    components: {
        Form,
        Field,
    },
    data() {
        return {
            userTypes: [],
            category: {
                user_types: [],
                label: "",
                label_en: "",
                order: 1,
            },
        };
    },
    setup() {
        const { t } = useI18n();
        const submitButton = ref<HTMLElement | null>(null);
        setCurrentPageBreadcrumbs(t("pages.driveManagement.categories.addCat"), [
            {
                link: "/administration/documents/categories",
                label: t("pages.driveManagement.categories.management"),
            },
        ]);
        return { t, submitButton };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.driveManagement.categories.addCat"), [
                {
                    link: "/administration/documents/categories",
                    label: this.t("pages.driveManagement.categories.management"),
                },
            ]);
        },
    },
    created() {
        this.fetchUserTypes();
        if (this.$route.query.id) {
            apiDrive.getCategory(this.$route.query.id).then((data) => {
                let category = data.category;
                this.category.user_types = category.user_types.map((user_type) => {
                    return user_type.id;
                });
                this.category.label = category.label;
                this.category.label_en = category.label_en;
                this.category.order = category.order;
            });
        }
    },
    methods: {
        addCategory() {
            buttonHandle.handleWaitingButton(this.submitButton);
            if (this.$route.query.id) {
                apiDrive.editCategory(this.$route.query.id, this.category).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        router.push({ name: "administration-documentcategories" });
                    }, 500);
                });
            } else {
                apiDrive.addCategory(this.category).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        router.push({ name: "administration-documentcategories" });
                    }, 500);
                });
            }
        },
        fetchUserTypes() {
            apiUserProfile.getUserTypes().then((response) => {
                this.userTypes = response.data.usertypes;
            });
        },
    },
});
