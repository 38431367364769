import ApiService from "@/core/services/ApiService";

const ADD_CATEGORY = "documentation/add-category/";
const GET_CATEGORY = "documentation/get-categories";
const GET_CATEGORY_URL = 'documentation/get-category/';
const EDIT_CATEGORY_URL = 'documentation/update-category/';
const DELETE_CATEGORY_URL = "documentation/delete-category/";
const ADD_DOCUMENT_URL = "documentation/add-document/";
const EDIT_DOCUMENT_URL = "documentation/update-document/";
const DELETE_DOCUMENT_URL = "documentation/delete-document/";
const GET_USER_DOCUMENTATION_URL = 'documentation/get-documentation';
const GET_DOCUMENT_URL = 'documentation/get-document/';
const GET_ALL_DOCUMENTS_URL = 'documentation/get-all-documents';

export default {
    addCategory(action) {
        ApiService.setHeader();
        return ApiService.post(ADD_CATEGORY, action);
    },
    getCategories() {
        ApiService.setHeader();
        return ApiService.get(GET_CATEGORY).then((response) => {
            return response.data;
        });
    },
    getCategory(category_id) {
        ApiService.setHeader();
        return ApiService.get(GET_CATEGORY_URL + category_id).then((response) => {
            return response.data.data;
        });
    },
    editCategory(category_id, payload) {
        ApiService.setHeader();
        return ApiService.post(EDIT_CATEGORY_URL + category_id + "/", payload);
    },
    getAllDocuments() {
        ApiService.setHeader();
        return ApiService.get(GET_ALL_DOCUMENTS_URL).then((response) => {
            return response.data;
        });
    },
    addDocument(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_DOCUMENT_URL, payload);
    },
    getDocument(document_id) {
        ApiService.setHeader();
        return ApiService.get(GET_DOCUMENT_URL + document_id).then((response) => {
            return response.data.data;
        });
    },
    editDocument(document_id, payload) {
        ApiService.setHeader();
        return ApiService.post(EDIT_DOCUMENT_URL + document_id + "/", payload);
    },
    deleteDocument(document_id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_DOCUMENT_URL + document_id + "/");
    },
    deleteCategory(document_id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_CATEGORY_URL + document_id + "/");
    },
    getUserDocumentation() {
        ApiService.setHeader();
        return ApiService.get(GET_USER_DOCUMENTATION_URL).then((response) => {
            return response.data.data;
        });
    },
};
